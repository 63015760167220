<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      submitBtnTitle="Add employee"
      title="Create employee"
      @submitPressed="submitPressed"
    />
    <employee-form entity="Employee" redirect="/employees" :requiredRole="true" :requiredPassword="true" :userObject="employee" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import EmployeeForm from './components/EmployeeForm.vue'

export default {
  components: {
    TitleBar,
    EmployeeForm
  },
  data() {
    return {
      action: null,
      employee: {
        givenName: '',
        familyName: '',
        role: null,
        telephone: '',
        email: '',
        onOff: false,
        enabled: true,
        file: null,
        password: ''
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  },
}
</script>
